@use "@scss/global.scss" as *;

.modal {
  height: max-content;
  max-height: 95vh;
  width: max-content;
  max-width: calc(100% - 32px);
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $purple-1;
  color: $purple-10;
  padding: 80px 40px 40px;

  &-close-box {
    position: absolute;
    top: 0px;
    right: 20px;

    .modal-close-btn {
    }
  }

  @include mq-max($md) {
    padding: 40px 16px;
    width: 100%;

    &-close-box {
      position: absolute;
      top: -60px;
      right: 0px;

      .modal-close-btn {
        color: $purple-1;

        svg path {
          stroke: $purple-1;
        }

        &:hover {
          background-color: $grey-6;
          svg path {
            stroke: $purple-1;
          }
        }
      }
    }
  }
}
