@use "@scss/global.scss" as *;

.top-ten-girls {
  padding: 96px 0px;
  display: flex;
  flex-flow: column nowrap;

  .title {
    padding: 0 $desktop-margin-x;
  }

  @include mq-max($md) {
    padding: 96px 0px;
    overflow-x: visible;

    .title {
      padding: 0 $mobile-margin-x;
    }
  }
}
