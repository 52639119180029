@use "@scss/global.scss" as *;

.loading-screen {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 25px;
  background-color: $purple-10;

  &-text {
    text-transform: uppercase;
    color: $grey-6;
  }
}
