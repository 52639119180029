@use "@scss/global.scss" as *;

.fallback {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  &-content {
    margin-top: 100px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 28px;

    .description {
      max-width: 600px;
    }
  }
}
