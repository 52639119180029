@use "@scss/global.scss" as *;

.main-layout {
  display: flex;

  .header-part {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: $navbar-z-index;
  }

  .sidebar-part {
    top: 0px;
    position: sticky;
    width: 80px;
    height: 100vh;
    flex-shrink: 0;
  }

  .main-part {
    flex-grow: 1;
    position: relative;

    .back-button {
      position: fixed;
      top: $navbar-h;
      left: 80px;
      width: 120px;
      height: 60px;
      background: $grey-10;
      z-index: $navbar-z-index;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile-toolbar-part {
    display: none;
  }

  @include mq-max($md) {
    .sidebar-part {
      display: none;
    }

    .main-part {
      margin-bottom: $mobile-toolbar-h;

      .back-button {
        left: 0;
      }
    }

    .mobile-toolbar-part {
      display: block;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: $mobile-toolbar-z-index;
    }
  }
}
