@use "@scss/global.scss" as *;

.toolbar-nav-item {
  @include p2();
  @include no-select();

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 5px;
  text-transform: uppercase;
  color: $grey-4;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;

  svg path {
    transition: all 0.3s ease-out;
    stroke: $grey-4;
    fill: transparent;
  }

  &:active {
    color: $purple-5;

    svg path {
      stroke: $purple-5;
      fill: $purple-5;
    }
  }

  &--active {
    color: $purple-5;

    svg path {
      stroke: $purple-5;
      fill: $purple-5;
    }
  }

  &--soon {
    pointer-events: none;
    color: $grey-5;

    svg path {
      stroke: $grey-5;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .coming-soon {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(-45deg) translate(-50%, 0);
    transform-origin: 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 200%;
    height: 45px;
    padding-bottom: 2px;
    background: $grey-6;
    box-shadow: $shadow-1;
    color: $grey-4;

    font-size: 14px;
    font-weight: $semi-bold;
    line-height: 150%;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }
}
