@use "@scss/global.scss" as *;

.character-sticker {
  display: flex;
  padding-right: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: max-content;
  border-radius: 29px;
  border: 1px solid $purple-8;
  background: $grey-9;

  &-main {
    display: flex;
    flex-flow: column nowrap;
  }
}
