@use "@scss/global.scss" as *;

.typing-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .dot {
    border-radius: 50%;
    // height: 10px;
    // width: 10px;
    background: $grey-6;
    animation: wave 0.8s infinite linear;
    will-change: auto;
  }

  .dot:nth-child(1) {
    animation-delay: 100ms;
  }
  .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  .dot:nth-child(3) {
    animation-delay: 400ms;
  }

  @keyframes wave {
    0% {
      transform: translateY(0px);
      background: $grey-6;
    }
    25% {
      transform: translateY(-5px);
      background: $purple-5;
    }
    50% {
      transform: translateY(0px);
      background: $grey-6;
    }
  }
}
