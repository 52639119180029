@use "@scss/global.scss" as *;

.girl-card {
  position: relative;
  max-width: 288px;
  height: 485px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .girl-image {
    overflow: hidden;
    object-fit: cover;
    object-position: top center;
    flex: 1;
  }

  .girl-type {
    position: absolute;
    top: 12px;
    right: 16px;
  }

  .card-content {
    position: absolute;
    padding: 12px 16px 24px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    .girl-names {
      display: flex;
      flex-flow: column nowrap;

      .name {
        font-size: 24px;
        line-height: 110%; /* 26.4px */
        letter-spacing: -0.48px;
        font-weight: $semi-bold;
        @include text-ellipsis(1);
      }

      .age {
        font-size: 14px;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.42px;
        font-weight: $regular;
        @include text-ellipsis(1);
      }
    }
  }

  &-coming-soon {
    opacity: 0.6;
  }

  .coming-soon-label {
    display: flex;
    justify-content: center;
    width: 158px;
    height: 68px;
    padding: 5px;
    font-size: 21px;
    font-weight: $medium;
    line-height: 150%;
    letter-spacing: 0.42px;
    background: $purple-10;
    color: $white-1;
    box-shadow: $shadow-2;
    text-transform: uppercase;
  }

  .top-left-coming-soon {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-45deg) translate(-50%, 0);
    transform-origin: 0 0;
    align-items: flex-end;
  }

  .bottom-right-coming-soon {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(-45deg) translate(50%, 0);
    transform-origin: 100% 100%;
    align-items: flex-start;
  }

  @include mq-max($md) {
    min-width: 100%;
    max-width: 343px;
    height: 70vh;
  }
}
