@use "@scss/global.scss" as *;

.sidebar-nav-item {
  @include no-select();

  display: flex;
  flex-flow: column nowrap;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
  text-transform: uppercase;
  color: $grey-4;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;

  svg path {
    transition: all 0.3s ease-in-out;
    stroke: $grey-4;
  }

  &:hover:not(&--active) {
    color: $purple-4;

    svg path {
      stroke: $purple-4;
    }
  }

  &--active {
    color: $purple-2;

    svg path {
      stroke: $purple-5;
      fill: $purple-5;
    }
  }

  &--soon {
    pointer-events: none;
    color: $grey-5;

    svg path {
      stroke: $grey-5;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .coming-soon {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(-45deg) translate(-50%, 0);
    transform-origin: 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 200%;
    height: 45px;
    padding-bottom: 2px;
    background: $grey-6;
    box-shadow: $shadow-1;
    color: $grey-4;

    font-size: 14px;
    font-weight: $semi-bold;
    line-height: 150%;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }
}
