@use "@scss/global.scss" as *;

.icon-link {
  display: flex;
  width: max-content;
  height: max-content;
  text-decoration: none;
  cursor: pointer;

  svg path {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    svg path {
      fill: $purple-5;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
