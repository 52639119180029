@use "@scss/global.scss" as *;

.girl-profile {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;

  .profile-images,
  .profile-info {
    display: flex;
    width: 50%;
  }

  .profile-images {
    // width: 50%;
    position: relative;

    .character-type-icon {
      position: absolute;
      top: 80px;
      right: 10px;
      z-index: 1;
    }
  }

  .profile-info {
    // width: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 16px 16px 16px 60px;

    &-content {
      max-width: 576px;
      display: flex;
      flex-flow: column nowrap;
    }

    .girl-category {
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    .girl-name {
      color: $purple-1;
      margin-bottom: 24px;
    }

    .girl-description {
      color: $grey-2;
      margin-bottom: 48px;
    }

    .girl-hobby {
      display: flex;
      flex-flow: column nowrap;
      gap: 15px;

      &-stickers {
        display: flex;
        flex-flow: row wrap;
        gap: 8px;
      }
    }

    .girl-personality {
      display: flex;
      flex-flow: column nowrap;
      gap: 15px;

      &-stickers {
        display: flex;
        flex-flow: row wrap;
        gap: 8px;
      }
    }

    &-character-stickers {
      display: flex;
      flex-flow: row wrap;
      gap: 8px;
    }

    &-breakLine {
      background: $grey-7;
      height: 1px;
      margin: 24px 0;
    }

    &-buttons {
      display: flex;
      width: 100%;
      gap: 16px;
    }
  }

  @include mq-max($md) {
    flex-flow: column nowrap;
    height: 100%;

    .profile-images,
    .profile-info {
      width: 100%;
    }

    .profile-images {
      height: 80vh;
    }

    .profile-info {
      justify-content: flex-start;
      padding: 40px 16px;

      &-content {
        max-width: 100%;
      }

      &-buttons {
        flex-flow: column nowrap;
      }
    }
  }
}
