@use "@scss/global.scss" as *;

.debug-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  // margin: 0 auto;
  // max-width: 500px;
  padding-top: 60px;
}
