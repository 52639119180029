@use "@scss/global.scss" as *;

.image-slider {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $grey-9;

  &-images {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;

    img {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      object-fit: contain;
      object-position: center;
    }
  }

  &-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(9, 1, 15, 0.5);
    transition: all 0.3 ease-in-out;

    svg path {
      transition: all 0.3 ease-in-out;
    }

    &:active {
      svg path {
        stroke: $purple-5;
      }
    }

    &.left-button {
      left: 15px;
    }

    &.right-button {
      right: 15px;
    }
  }

  &-pagination {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 90%;
    width: max-content;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    background: rgba(11, 9, 13, 0.4);

    .pagination-item {
      display: block;
      min-width: 4px;
      width: 100%;
      max-width: 40px;
      height: 4px;
      background-color: rgba($purple-1, 0.2);

      &.active {
        background-color: $purple-4;
      }
    }
  }
}
