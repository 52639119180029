@use "@scss/global.scss" as *;

.not-found {
  width: 100%;
  height: 100%;
  background-color: $white-1;
  color: $grey-6;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow: clip;

  &-content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 16px;
  }

  &-bg {
    position: relative;
    height: 363px;

    .bg-image {
      @include absolute-center();
      width: 461px;
      height: 363px;
    }
  }

  &-info {
    position: relative;
    top: -10%;
    max-width: 420px;
    display: flex;
    flex-flow: column nowrap;
    gap: 30px;

    .error-description {
      @include p0();
    }
  }

  @include mq-max($md) {
    &-bg {
      height: 307px;

      .bg-image {
        width: 390px;
        height: 307px;
      }
    }

    &-info {
      top: 4%;

      .error-description {
        @include p1();
      }
    }
  }
}
