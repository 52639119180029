@use "@scss/global.scss" as *;

.read-article {
  padding: 0 $desktop-margin-x;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: $purple-9;
  height: 322px;
  text-align: center;
  overflow: clip;
  position: relative;

  .read-article-bg-left {
    position: absolute;
    top: 0;
    left: 0;
    left: calc(50% - 380px);
    transform: translate(-50%);
  }

  .read-article-bg-right {
    position: absolute;
    top: 0;
    left: calc(50% + 140px);
  }

  .read-article-bg-mobile {
    display: none;
  }

  @include mq-max($lg) {
    .read-article-bg-left {
      left: calc(50% - 320px);
    }

    .read-article-bg-right {
      left: calc(50% + 90px);
    }
  }

  @include mq-max($md) {
    padding-top: 96px;
    flex-flow: column nowrap;
    height: 770px;
    justify-content: flex-start;

    .read-article-bg-left,
    .read-article-bg-right {
      display: none;
    }

    .read-article-bg-mobile {
      display: flex;
      padding-top: 24px;
      position: absolute;
      bottom: -125px;
    }
  }
}
