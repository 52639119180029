@use "@scss/global.scss" as *;

.spinner {
  display: flex;

  &-circle {
    animation: spin-left 0.5s linear infinite;
  }

  @keyframes spin-left {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
