@use "@scss/global.scss" as *;

.modal-button {
  width: max-content;
  font-family: "Acumin Pro", sans-serif;
  font-size: 14px;
  font-weight: $semi-bold;
  line-height: 140%; /* 19.6px */
  cursor: pointer;
  height: 48px;
  max-width: max-content;
  transition: all 0.3s ease-in-out;

  svg path {
    transition: all 0.3s ease-in-out;
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &-primary {
    padding: 20px;
    color: $purple-1;
    background-color: $purple-5;

    svg path {
      stroke: $purple-1;
    }

    &:hover {
      background-color: $purple-4;
    }
    &:active {
      background-color: $purple-6;
    }
    &:focus-visible {
      outline: 2px solid $purple-4;
    }
  }

  &-secondary {
    padding: 20px;
    color: $purple-10;
    border: 1px solid $purple-10;
    background-color: transparent;

    svg path {
      stroke: $purple-10;
    }

    &:hover {
      background-color: $grey-2;
    }
    &:active {
      background-color: $grey-3;
    }
    &:focus-visible {
      border-color: $grey-3;
      outline: 3px solid $grey-3;
    }
  }

  &-tertiary {
    padding: 20px 0;
    height: 60px;
    color: $purple-10;
    background-color: transparent;

    svg path {
      stroke: $purple-10;
    }

    &:hover {
      background-color: $grey-2;
    }
    &:active {
      background-color: $grey-3;
    }
    &:focus-visible {
      outline: 2px solid $grey-2;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.full-width {
    width: 100%;
    max-width: unset;
  }
}
