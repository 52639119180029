@use "@scss/global.scss" as *;

.desktop-unsupported-browser {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 16px;
  width: 650px;

  &-modal-btn {
    min-width: 137px;
  }

  @include mq-max($md) {
    width: 100%;

    img {
      width: 50px;
      height: 50px;
    }
  }
}
