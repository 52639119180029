@use "@scss/global.scss" as *;

.age-verification-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 550px;
  text-align: center;

  &-buttons {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    justify-content: center;

    .modal-button {
      min-width: 200px;
    }
  }

  @include mq-max($md) {
    width: 100%;

    &-buttons {
      flex-flow: column nowrap;

      .confirm-btn {
        order: 1;
      }

      .reject-btn {
        order: 2;
      }
    }
  }
}
