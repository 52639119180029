@use "@scss/global.scss" as *;

.hero {
  position: relative;
  height: 100vh;

  &-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-content {
    position: relative;
    width: 100%;
    max-width: 1240px;
    height: 100%;
    padding: 80px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;

    .subtitle-box {
      display: flex;
      padding: 8px 16px;
      align-items: flex-start;
      gap: 8px;
      background: $purple-5;
      width: max-content;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }

    .hero-title {
      @include display-3;
    }
  }

  @include mq-max($md) {
    &-content {
      padding: 40px $mobile-margin-x;
      width: 100%;

      .subtitle-box {
        width: 100%;
      }
    }
  }
}
