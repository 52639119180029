@use "@scss/global.scss" as *;

.categories {
  padding: 38px 0px 96px;
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;

  .categories-title,
  .category-name {
    padding: 0 $desktop-margin-x;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 60px;

    .category {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;
    }
  }

  @include mq-max($md) {
    padding: 38px 0 96px;

    .categories-title,
    .category-name {
      padding: 0 $mobile-margin-x;
    }
  }
}
