@use "./src/scss/global.scss" as *;

.switch-network-modal {
  width: 550px;
  text-align: center;

  @include mq-max($md) {
    width: 100%;
  }
}
