@use "@scss/global.scss" as *;

.backdrop-wrapper {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;

  .backdrop {
    height: 100%;
    width: 100%;
    background-color: rgba($purple-10, 0.6);
  }

  &.open {
    display: block;
  }
}
