@use "@scss/global.scss" as *;

.connect-to-metamask {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  gap: 24px;
  text-align: center;

  .description {
    max-width: 530px;
  }

  .term-of-service-link {
    @include p1();
    display: inline-block;
    color: $purple-5;

    &:hover {
      color: $purple-6;
    }
  }
}
