@use "@scss/global.scss" as *;

.main-sidebar {
  height: 100%;
  padding: 80px 0 48px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: $grey-8;

  .sidebar-actions {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .social-links {
    display: flex;
    flex-flow: column nowrap;
    gap: 21px;
  }
}
