@use "@scss/global.scss" as *;

.mobile-unsupported-browser {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 550px;

  & .open-metamask-link {
    color: $purple-5;

    svg path {
      stroke: $purple-5;
    }
  }

  @include mq-max($md) {
    width: 100%;
  }
}
