@use "@scss/global.scss" as *;

.base-layout {
  .header-part {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: $navbar-z-index;
  }

  .main-part {
    height: max-content;
  }

  .footer-part {
  }
}
