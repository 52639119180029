@use "@scss/global.scss" as *;

.desktop-download-metamask {
  max-width: 550px;

  & .open-metamask-link {
    color: $purple-5;

    svg path {
      stroke: $purple-5;
    }
  }
}
