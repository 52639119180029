@use "@scss/global.scss" as *;

.top-card {
  position: relative;
  max-width: 260px;
  height: 480px;
  display: flex;
  flex-direction: column;

  .character-image {
    overflow: hidden;
    object-fit: cover;
    object-position: top center;
    flex: 1;
  }

  .character-type {
    position: absolute;
    top: 12px;
    right: 8px;
  }

  .character-details {
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    color: $white-1;
    column-gap: 8px;

    .character-rank {
      font-size: 56px;
      line-height: 90%; /* 50.4px */
      letter-spacing: -1.68px;
      font-weight: $bold;
    }

    .character-names {
      display: flex;
      flex-flow: column nowrap;

      .name {
        font-size: 24px;
        line-height: 110%; /* 26.4px */
        letter-spacing: -0.48px;
        font-weight: $semi-bold;
        @include text-ellipsis(1);
      }

      .category {
        font-size: 14px;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.42px;
        font-weight: $regular;
        @include text-ellipsis(1);
      }
    }
  }

  @include mq-max($md) {
    min-width: 100%;
    max-width: 343px;
    height: 70vh;

    .character-details {
      margin-left: 8px;
    }
  }
}
